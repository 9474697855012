<template>
<v-sheet>
  <h1>News Single is herer</h1>
</v-sheet>
</template>

<script>
export default {
  name: "DefaultSiteHome"
}
</script>

<style scoped>

</style>