<template>
<div>
  <h2 class="wsDARKER mb-2"
      :class="[{'mt-12' : !SM} , {'mt-6' : SM}]"
      :style="`font-size: ${!SM ? 24 : 19}px`"
  >
    {{  $t('market.course.content.title')  }}
  </h2>
  <market-course-module
      v-for="(module,i) in entity.modules" :key="i"
      :module="module"
      class="mb-2"
      :expanded="i === 0"
  />

</div>
</template>

<script>
import marketCourseModule from "@/components/AvalonEditor/defaultSite/Course/marketCourseModule.vue";

export default {
  name: "marketCourseContent",
  components : {
    marketCourseModule
  },
  props : {
    entity : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      selectedPlan : null
    }
  }
}
</script>

<style scoped>

</style>