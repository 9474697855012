<template>
<v-sheet class="d-flex" :color="wsHEADER_BG">
  <v-sheet
      color="transparent"
      width="1240"
      class="d-flex align-center justify-space-between py-2 px-2 mx-auto"
  >
    <!-- LOGO -->

    <router-link  :to="businessPublicLink('')" class="noUnderline">
      <img
          v-if="!$store.state.business.selectedBusiness.logo"
          class="text-center pointer  mt-2"
          :height="!SM ? 32 : 24"
          width="auto"
          alt="WeStudy"
          src="/library/img/westudy_logo.png"
      />
      <div v-else class="d-flex align-center py-1 ">
        <img
            class="text-center pointer mr-3 "
            :height="!SM ? 56 : 32"
            width="auto"
            alt="WeStudy"
            :src="$store.state.business.selectedBusiness.logo"
        />
        <h4 v-if="HEADER_BUSINESS_TEXT" style="max-width: 250px" :style="`color : ${wsHEADER_TEXT}`">{{ $store.state.business.selectedBusiness.name }}</h4>
      </div>

    </router-link>

    <div class="d-flex align-center">
      <ws-lang-select
          class="mr-4"
          :text-color="wsHEADER_TEXT"
          :activator-style="`color : ${wsHEADER_TEXT}`"
          :text-style="`color : ${wsHEADER_TEXT}`"
          autohide
      />
      <ws-button
          :to="businessDashLink('')"
          :color="DARK_HEADER ? wsHEADER_TEXT : wsATTENTION"
          :outlined="DARK_HEADER"
          :label="!$store.state.auth.loggedIn ? 'Login' : 'PersonalDashboard' " />
    </div>

  </v-sheet>
</v-sheet>
</template>

<script>
import wsLangSelect from "@/components/UI/wsLangSelect";
export default {
  name: "defaultSiteHeader",
  components : {
    wsLangSelect
  }
}
</script>

<style scoped>

</style>