<template>
<v-sheet   class="d-flex" :color="wsFOOTER_BG">
  <v-sheet width="1240" class="d-flex align-center justify-space-between py-7 px-2 mx-auto" color="transparent">

    <div class="d-flex align-center">
      <!-- LOGO -->
      <router-link class="noUnderline mr-4" :to="businessPublicLink('')">
        <img
            v-if="!$store.state.business.selectedBusiness.logo"
            class="text-center pointer  mt-2"
            height="32"
            width="36"
            alt="WeStudy"
            style="object-fit: cover;object-position: 0; opacity: 0.6"
            src="/library/img/westudy_logo.png"
        />
        <img
            v-else
            class="text-center pointer mr-3 "
            height="32"
            width="auto"
            alt="WeStudy"
            :src="$store.state.business.selectedBusiness.logo"
        />

      </router-link>
      <div class="d-flex align-center">

        <h5 class="font-weight-regular mr-4" :style="`color : ${wsFOOTER_TEXT}`">
          {{ $store.state.business.selectedBusiness.name }}
        </h5>

        <router-link
            v-for="(item , i) in documentsList" :key="`documents_${i}`"
            :style="`color : ${wsFOOTER_TEXT} !important`"
            class="noUnderline linkHover wsACCENT"
            :to="item.route">
          <h4 :style="`color : ${wsFOOTER_TEXT} !important`" class="font-weight-regular" :class="[{'text-center' : SM} , {'mr-4' : !SM}]"  style="font-size: 13px">{{ $t(item.text) }}</h4>
        </router-link>
      </div>

    </div>




    <router-link :to="localeLink('')" class="noUnderline linkHover" target="_blank">
      <h5 :style="`color : ${wsFOOTER_TEXT}`">© {{ (new Date()).getFullYear() }} - WeStudy ™</h5>
    </router-link>

  </v-sheet>
</v-sheet>
</template>

<script>
export default {
  name: "defaultSiteHeader",
  computed : {
    documentsList() {
      return []
      // return [
      //   { text : 'TermsAndConditions' , route : this.localeLink('about/terms')  },
      //   { text : 'ConfidentialRules'  , route : this.localeLink('about/confidential')},
      //   { text : 'HomeOffer'          , route : this.localeLink('about/offer')  },
      //   { text : 'RefundRules'        , route : this.localeLink('about/refund')  },
      // ]
    }
  }
}
</script>

<style scoped>

</style>